.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

thead tr {
    background-color: #343a40; /* Dark color for header */
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}

thead th {
    padding: 12px 15px;
    cursor: pointer;
    user-select: none;
}

tbody tr {
    border-bottom: 1px solid #dddddd;
    transition: background-color 0.2s ease;
}

tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
}

tbody tr:last-of-type {
    border-bottom: 2px solid #343a40;
}

tbody tr:hover {
    background-color: #e2e8f0;
}

td {
    padding: 12px 15px;
}

tbody tr.veryHighOdds {
    background-color: #e0f2f1 !important; /* Light teal */
}

tbody tr.highOdds {
    background-color: #b2dfdb !important; /* Teal */
}

tbody tr.mediumHighOdds {
    background-color: #80cbc4 !important; /* Medium teal */
}

tbody tr.mediumOdds {
    background-color: #4db6ac !important; /* Dark teal */
}

tbody tr.mediumLowOdds {
    background-color: #ffab91 !important; /* Light coral */
}

tbody tr.lowOdds {
    background-color: #ff8a65 !important; /* Coral */
}

tbody tr.veryLowOdds {
    background-color: #ff7043 !important; /* Dark coral */
}
